import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import Stack from '../components/systems/stack';
import SEO from '../components/layout/seo';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <SEO title="Impressum" shouldIndex={false} mdxType="SEO" />
    <Stack gap="15px" centered={false} mdxType="Stack">
      <h1>{`Impressum`}</h1>
      <p>{`Angaben gemäß § 5 TMG`}</p>
      <h2>{`Beate Landgraf`}</h2>
      <p>{`Psychologin (M.Sc.)
Fachtherapeutin für Traumatherapie (DGGTP)
Heilpraktikerin für Psychotherapie (HeilprG)`}</p>
      <p>{`Lindenweg 36, 91091 Großenseebach`}</p>
      <p>{`Mobil:   0175 / 4 74 45 61
E-Mail:  `}<a parentName="p" {...{
          "href": "mailto:mail@praxis-landgraf.de"
        }}>{`mail@praxis-landgraf.de`}</a></p>
      <p><strong parentName="p">{`Inhaltlich Verantwortlicher`}</strong>{` gemäß i.S.d. § 18 Abs. 2 MStV: Beate Landgraf (Anschrift wie oben)
`}<strong parentName="p">{`Umsatzsteuer-ID`}</strong>{` gemäß § 27 a Umsatzsteuergesetz: 216/243/20988`}</p>
      <p>{`Ansprechpartner für Datenschutzangelegenheiten in Bayern:
Herr Dr. Thomas Petri
Wagmüllerstr. 18, 80538 München
089/21 26 72-0
`}<a parentName="p" {...{
          "href": "mailto:poststelle@datenschutz-bayern.de"
        }}>{`poststelle@datenschutz-bayern.de`}</a></p>
      <p><strong parentName="p">{`EU-Streitschlichtung`}</strong></p>
      <p>{`Die Europäische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit:
`}<a parentName="p" {...{
          "href": "https://ec.europa.eu/consumers/odr"
        }}>{`https://ec.europa.eu/consumers/odr`}</a>{`. Unsere E-Mail-Adresse finden Sie oben im Impressum.`}</p>
      <p><strong parentName="p">{`Verbraucherstreitbeilegung/Universalschlichtungsstelle`}</strong></p>
      <p>{`Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer
Verbraucherschlichtungsstelle teilzunehmen.`}</p>
      <p>{`Trotz sorgfältiger inhaltlicher Kontrolle übernehme ich keine Haftung für die Inhalte externer
Links. Für den Inhalt der verlinkten Seiten sind ausschließlich deren Betreiber verantwortlich.`}</p>
    </Stack>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      